<template>
    <div>
        <van-nav-bar :title="$props.nav" left-arrow @click-left="onClickLeft" />
    </div>
</template>

<script>
export default {
    props: ['nav'],
    methods: {
        onClickLeft(){
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>
.van-nav-bar {
    background-color: #3861FB !important;
}
::v-deep .van-icon {
    color: #FFF;
}
::v-deep .van-nav-bar__title {
    color: #fff !important;
    font-family: SF Pro;
    font-size: 4.0712vw;
    font-weight: 510;
}
</style>