<template>
    <div>
        <navBar nav="订单申诉" />
        <div class="text">我们会尽快处理您的申诉请求，请耐心等待</div>
        <van-field v-model="remark" rows="7" autosize type="textarea" placeholder="请简要描述您申诉的原因（300字以内）" />
        <div class="btn" @click="sure">提交</div>
    </div>
</template>

<script>
import navBar from "../../components/navBar/index.vue"
export default {
    components: {
        navBar
    },
    data() {
        return {
            remark: ''
        }
    },
    methods: {
        sure() {
            const params = {
                orderSn: this.$route.query.num,
                remark: this.remark
            }
            this.$http.post(this.host + "/otc/order/appeal", params).then((res) => {
                if (res.data.code == 500) {
                    this.$toast(res.data.message)
                } else {
                    this.$toast(res.data.message)
                    this.$router.go(-1)
                }
            });
        }
    }
}
</script>

<style scoped>
.btn {
    width: 76.3359vw;
    height: 7.6336vw;
    background-color: #3861FB;
    border-radius: 5.0891vw;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    line-height: 7.6336vw;
    letter-spacing: .2545vw;
    margin-top: 10.1781vw
}

::v-deep .van-cell {
    width: 91.6031vw;
    border: .2545vw solid #aaa;
    border-radius: 1.5267vw;
    margin: 0 auto;
}

.text {
    color: #aaa;
    margin-top: 5.0891vw;
    margin-left: 5.0891vw;
    margin-bottom: 5.0891vw;
}
</style>